import React from "react";
import { observer } from "mobx-react";
import { Button, Toast } from "antd-mobile";
import "antd-mobile/dist/antd-mobile.css";
import history from "../../../libs/history";
import store from "./store";
import { CopyToClipboard } from "react-copy-to-clipboard";

@observer
class ExpressResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  copyExpress = (info) => {
    navigator.clipboard.writeText(info);
    Toast.info("快递单号已复制！");
  };

  handleBack = () => {
    history.push("/student/express_search");
  };

  render() {
    console.log(111, store.express_info);
    return (
      <React.Fragment>
        <div className="container">
          <div className="title">
            <span>马哥教育礼品快递信息</span>
          </div>

          {store.express_info.map((item, idx) => (
            <div key={item.id} className="box">
              <div className="box_title">快递公司：{item.express}</div>
              <div className="box_title">
                快递单号：{item.express_num}
                <CopyToClipboard text={item.express_num} 
                  onCopy={()=> Toast.info('快递单号已复制！')}
                >
                  <span><svg
                    t="1649903355000"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="3340"
                    width="15"
                    height="15"
                  >
                    <path
                      d="M878.250667 981.333333H375.338667a104.661333 104.661333 0 0 1-104.661334-104.661333V375.338667a104.661333 104.661333 0 0 1 104.661334-104.661334h502.912a104.661333 104.661333 0 0 1 104.661333 104.661334v502.912C981.333333 934.485333 934.485333 981.333333 878.250667 981.333333zM375.338667 364.373333a10.666667 10.666667 0 0 0-10.922667 10.965334v502.912c0 6.229333 4.693333 10.922667 10.922667 10.922666h502.912a10.666667 10.666667 0 0 0 10.922666-10.922666V375.338667a10.666667 10.666667 0 0 0-10.922666-10.922667H375.338667z"
                      p-id="3341"
                    ></path>
                    <path
                      d="M192.597333 753.322667H147.328A104.661333 104.661333 0 0 1 42.666667 648.661333V147.328A104.661333 104.661333 0 0 1 147.328 42.666667H650.24a104.661333 104.661333 0 0 1 104.618667 104.661333v49.962667c0 26.538667-20.309333 46.848-46.848 46.848a46.037333 46.037333 0 0 1-46.848-46.848V147.328a10.666667 10.666667 0 0 0-10.922667-10.965333H147.328a10.666667 10.666667 0 0 0-10.965333 10.965333V650.24c0 6.229333 4.693333 10.922667 10.965333 10.922667h45.269333c26.538667 0 46.848 20.309333 46.848 46.848 0 26.538667-21.845333 45.312-46.848 45.312z"
                      p-id="3342"
                    ></path>
                  </svg>
                  </span>
                </CopyToClipboard>
              </div>
              <div className="box_title">礼品信息：{item.goods}</div>
              <div className="box_title">收件姓名：{item.username}</div>
              <div className="box_title">收件电话：{item.mobile}</div>
              <div className="box_title">发出时间：{item.created_at}</div>
            </div>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            color: "red",
            fontSize: "25px",
            textAlign: "center",
          }}
        >
          <Button
            icon="left"
            inline
            size="small"
            style={{ marginRight: "4px" }}
            type="primary"
            onClick={this.handleBack}
          >
            返回
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default ExpressResult;
