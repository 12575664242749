import React from 'react';
import { observer } from 'mobx-react'
import {Card, Descriptions, Modal} from 'antd';
import store from './store';

@observer
class StudentDetail extends React.Component {
    render() {
        const info = store.record;
        return (
            <Modal
            visible
            width="80%"
            maskClosable={false}
            title={'学员详细信息'}
            onCancel={() => store.detailVisible = false}
            onOk={() => store.detailVisible = false}>
            <div>
                <Card>
                    <Descriptions  title="学员信息">
                        <Descriptions.Item label="姓名">{info['name']}</Descriptions.Item>
                        <Descriptions.Item label="年龄"> {info['age']} </Descriptions.Item>
                        <Descriptions.Item label="性别"> {info['sex']} </Descriptions.Item>
                        <Descriptions.Item label="班级"> {info['class_name']} </Descriptions.Item>
                        <Descriptions.Item label="手机号"> {info['mobile']} </Descriptions.Item>
                        <Descriptions.Item label="是否授权">{info['is_authorized']}</Descriptions.Item>
                        <Descriptions.Item label="授权号码">{info['authorized_num']}</Descriptions.Item>
                        <Descriptions.Item label="所在地">{info['address']}</Descriptions.Item>
                        <Descriptions.Item label="QQ">{info['qq']}</Descriptions.Item>
                        <Descriptions.Item label="毕业学校">{info['school']}</Descriptions.Item>
                        <Descriptions.Item label="目前年薪">{info['salary_now']}</Descriptions.Item>
                        <Descriptions.Item label="期望年薪">{info['salary_expect']}</Descriptions.Item>
                        <Descriptions.Item label="课程顾问">{info['counselor']}</Descriptions.Item>
                        <Descriptions.Item label="积分数量">{info['coin']}</Descriptions.Item>
                        <Descriptions.Item label="选择马哥教育的理由">{info['join_reason']}</Descriptions.Item>
                        <Descriptions.Item label="支付渠道">{info['pay_channel']}</Descriptions.Item>
                        <Descriptions.Item label="支付时间">{info['pay_time']}</Descriptions.Item>
                        <Descriptions.Item label="获知课程途径">{info['know_by']}</Descriptions.Item>
                        <Descriptions.Item label="提交时间">{info['created_at']}</Descriptions.Item>
                        <Descriptions.Item label="备注信息">{info['desc']}</Descriptions.Item>
                        <Descriptions.Item label="收件地址">{info['delivery_address']}</Descriptions.Item>
                        <Descriptions.Item label="邮箱地址">{info['email_address']}</Descriptions.Item>
                        {/*<Descriptions.Item label="状态">*/}
                        {/*    <Tag color="#87d068">{info['status_alias']}</Tag>*/}
                        {/*</Descriptions.Item>*/}
                    </Descriptions>
                </Card>
            </div>
            </Modal>
        )
    }
}

export default StudentDetail

