import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Form, Input, Select, message } from 'antd';
import http from 'libs/http';
import store from './store';
import ClassStore from '../../student/class/store'
import StudentStore from '../../student/info/store'

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    if (ClassStore.records.length === 0 ){
      ClassStore.fetchRecords()
    }
    if (StudentStore.records.length === 0 ){
      StudentStore.fetchRecords()
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    http.post('/api/homework/record/', formData)
      .then(res => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={800}
        maskClosable={false}
        title={store.record.id ? '编辑作业记录' : '新建作业记录'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="班级名称">
            {getFieldDecorator('student_class_id', {initialValue: info['student_class_id']})(
              <Select placeholder="请选择班级" showSearch>
                {ClassStore.records.map(item => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="学员姓名">
            {getFieldDecorator('student_id', {initialValue: info['student_id']})(
              <Select placeholder="请选择学员" showSearch>
                {StudentStore.records.map(item => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="周次">
            {getFieldDecorator('week', {initialValue: info['week']})(
              <Input placeholder="请输入周次"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="作业链接">
            {getFieldDecorator('detail', { initialValue: info['detail']})(
             <Input.TextArea rows={4} placeholder="请输入作业链接"/>
             )}
          </Form.Item>
          <Form.Item {...itemLayout} label="备注信息">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input.TextArea placeholder="请输入备注信息"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)
