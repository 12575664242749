import React from 'react';
import { observer } from 'mobx-react';
import {Modal, Form, Input, Select, message} from 'antd';
import http from 'libs/http';
import store from './store';
import ClassStore from '../student/class/store'
import _ from "lodash";

@observer
class AddForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    if (ClassStore.records.length === 0 ){
      ClassStore.fetchRecords()
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['student_class_id'] = store.select_student['at_class_id'];
    formData['student_id'] =  store.select_student['id'];
    http.post('/api/feedback/detail/', formData)
      .then(res => {
        message.success('操作成功');
        store.addVisible = false;
        store.getStudentFeed()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={800}
        maskClosable={false}
        title={store.record.id ? '编辑会回访' : '新建回访'}
        onCancel={() => store.addVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="学员班级">
            {getFieldDecorator('class_name', {initialValue: store.select_student['class_name']})(
              <Input disabled={true} />
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="学员姓名">
            {getFieldDecorator('name', {initialValue: store.select_student['name']})(
              <Input disabled={true}  />
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="周次">
            {getFieldDecorator('week', {initialValue: info['week']})(
              <Select placeholder="请选择周次">
                {_.range(1,30).map(item=> (
                  <Select.Option key={item} value={item}>第{item}周</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
           <Form.Item {...itemLayout} label="回访内容">
            {getFieldDecorator('detail', {initialValue: info['detail']})(
              <Input.TextArea rows={4} placeholder="请输入回访内容信息"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="备注信息">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input.TextArea placeholder="请输入备注信息"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(AddForm)


