import { observable } from "mobx";
import http from 'libs/http';
import history from "libs/history";

class Store {
  @observable records = [];
  @observable history = [];
  @observable record = {};
  @observable recordTotal = '';
  @observable isFetching = false;
  @observable historyFetching = false;
  @observable formVisible = false;
  @observable detailVisible = false;

  @observable f_name;
  @observable f_class_type;
  @observable student_coin = {};


  fetchRecords = (page=1, page_size=10) => {
    this.isFetching = true;
    http.get('/api/student/',
      {params: {page: page, page_size: page_size,name: this.f_name, class_type: this.f_class_type}
      }).then(res => {
      this.records = res.data;
      this.recordTotal = res.total;
    })
      .finally(() => this.isFetching = false);
  };

  handlePage = (info) => {
    this.fetchRecords(info.current, info.pageSize);
  };

  handleSearch = (info) => {
    this.fetchRecords(info.current, info.pageSize);

  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info
  };
  showHistoryInner = (info = {}) => {
    this.detailVisible = true;
    this.historyFetching = true;
    http.get('/api/student/coin/',
      {params: {'student_id': info.id}}).then((res)=> {
      this.history = res;
    }).finally(() => this.historyFetching=false)
  };

  searchStuCoin = (info = {}) => {
    http.get('/api/app/coin/',
      {params: {'mobile': info['mobile']}}).then((res)=> {
      this.student_coin = res;
      history.push('/student/coin_info');
    }).finally()
  };

  handleTohHistory = () => {
    http.get('/api/app/coin/history/',
      {params: {'student_id': this.student_coin.id}}).then((res)=> {
      this.history = res;
    }).finally();
    history.push('/student/coin_history');
  };
}

export default new Store()
