import React from 'react';
import { observer } from 'mobx-react';
import { Card, Input, Button } from 'antd';
import { SearchForm } from 'components';
import ComTable from './Table';
import store from './store';

export default observer(function () {
  return (
    <div>
    <Card >
      <SearchForm>
        <SearchForm.Item span={8} title="班级">
          <Input allowClear onChange={e => store.f_class = e.target.value} placeholder="班级"/>
        </SearchForm.Item>
        <SearchForm.Item span={8}>
          <Button type="primary" icon="sync" onClick={store.fetchRecords}>刷新</Button>
        </SearchForm.Item>
      </SearchForm>
    </Card>
      <div style={{ 'marginTop': '15px'}}>
      <ComTable/>
    </div>
    </div>
  )
})
