import React from 'react';
import { observer } from 'mobx-react';
import {Table, Divider, Modal, message, Tag} from 'antd';
import ComForm from './Form';
import http from 'libs/http';
import store from './store';
import { LinkButton } from "components";
import StudentDetail from './Detail';
import ClassStore from "../class/store";

@observer
class ComTable extends React.Component {
  componentDidMount() {
    store.fetchRecords();
    if (ClassStore.records.length === 0) {
      ClassStore.fetchRecords()
    }
  }

  columns = [ {
    title: '课程名称',
    dataIndex: 'class_type',
  },{
    title: '班级期数',
    dataIndex: 'class_name',
  },{
    title: '姓名',
    dataIndex: 'name',
  }, {
    title: '年龄',
    dataIndex: 'age',
  }, {
    title: '手机',
    dataIndex: 'mobile'
  }, {
    title: '学历',
    dataIndex: 'education'
  }, {
    title: '目前岗位',
    dataIndex: 'job'
  },{
    title: '目前年薪',
    dataIndex: 'salary_now'
  },{
    title: '期望年薪',
    dataIndex: 'salary_expect'
  },{
    title: '选择马哥教育理由',
    dataIndex: 'join_reason'
  },{
    title: '标记',
    render: info => {
      if (info['is_weird']){
        return <Tag color="#f50" style={{cursor: 'pointer'}} onClick={() => this.handelMark(info)} >特殊</Tag>
      }else {
        return <Tag color="#87d068" style={{cursor: 'pointer'}} onClick={() =>this.handelMark(info)} >正常</Tag>
      }
    }
  },{
    title: '操作',
    render: info => (
      <span>
        <LinkButton auth="student.info.detail"  onClick={() => store.showDetail(info)}>详情</LinkButton>
        <Divider type="vertical"/>
        <LinkButton auth="student.info.edit"  onClick={() => store.showForm(info)}>编辑</LinkButton>
        <Divider type="vertical"/>
        <LinkButton auth="student.info.del"  onClick={() => this.handleDelete(info)}>删除</LinkButton>
      </span>
    )
  }];

  handelMark = (info) => {
    Modal.confirm({
      title: '更新学员标记',
      content: `确定更新【${info['name']}】学员标记?`,
      onOk(){
        info['is_weird'] = !info['is_weird'];
        http.patch('/api/student/', info).then(()=>{
          message.success('更新成功');
          store.fetchRecords()
        })
      }
    });
  };

  handleDelete = (text) => {
    Modal.confirm({
      title: '删除确认',
      content: `确定要删除【${text['name']}】?`,
      onOk: () => {
        return http.delete('/api/student/', {params: {id: text.id}})
          .then(() => {
            message.success('删除成功');
            store.fetchRecords()
          })
      }
    })
  };

  render() {
    let data = store.records;
    return (
      <React.Fragment>
        <Table rowKey="id" loading={store.isFetching} dataSource={data}
               showTotal
               columns={this.columns}
               pagination={{'total': store.recordTotal, showTotal: total => `总数量 ${total}`}}
               onChange={store.handlePage}/>
        {store.formVisible && <ComForm/>}
        {store.detailVisible && <StudentDetail/>}
      </React.Fragment>
    )
  }
}

export default ComTable
