import {observable} from "mobx";
import http from 'libs/http';
import history from 'libs/history'
import classStore from '../student/class/store'


class Store {
  @observable records = [];
  @observable types = [];
  @observable record = {};
  @observable isFetching = false;
  @observable detailVisible = false;
  @observable tabBarStatus = true;
  @observable addVisible = false;
  @observable class_student_info = [];
  @observable student_id = '';
  @observable select_student = '';
  @observable student_class_id = '';
  @observable studentFeedback = [];
  @observable studentHomework = [];
  @observable selectStudent = [];

  @observable f_class;

  fetchRecords = () => {
    this.isFetching = true;
    if (classStore.records.length === 0) {
      classStore.fetchRecords()
    } else {
      classStore.fetchRecords();
    }
    this.isFetching = false;
  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info
  };
  showClassStudent = (info = {}) => {
    this.student_class_id = info.id;
    http.get('/api/student/', {params: {'class_id': this.student_class_id}}).then((res) => {
      // this.class_student_info = res;
      const tmp = {};
      for (let item of res) {
        tmp[item.id] = item
      }
      this.class_student_info = tmp;
      console.log('this.class_student_info', this.class_student_info);
    });

    this.getStudentFeed();
    history.push('/feedback/detail');
  };

  showStudentDetail = (info) => {
    this.tabBarStatus = false;
    this.student_id = info.key;
    this.getStudentFeed();
    this.getStudentHomework();
  };

  getStudentFeed = () => {
    http.get('/api/feedback/detail/', {
      params:
        {'student_id': this.student_id, 'student_class_id': this.student_class_id}
    }).then((res) => {
      this.studentFeedback = res;
    });
  };

  getSelectStudent = (info) => {
    this.select_student = this.class_student_info[info.key];
    console.log('this.select_student', this.select_student);
  };
  getStudentHomework = () => {
    http.get('/api/homework/record/', {
      params:
        {'student_id': this.student_id, 'student_class_id': this.student_class_id}
    }).then((res) => {
      this.studentHomework = res;
    });
  };

  handelStuDetail = (key) => {
    if (key === 1) {
      this.getStudentFeed();
    } else {
      this.getStudentHomework();
    }
  };

  addFeedback = () => {
    if (this.student_id !== '') {
      this.addVisible = true;

    }

  }


}

export default new Store()
