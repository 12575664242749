import React from 'react';
import { observer } from 'mobx-react';
import {Form, Input, Select, Button} from 'antd';
import http from 'libs/http';
import history from 'libs/history'
import store from './store';
import ClassStore from '../../student/class/store'
import _ from 'lodash'
import styles from './homework.module.css'

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    if (ClassStore.records.length === 0 ){
      ClassStore.fetchRecords()
    }
    // if (StudentStore.records.length === 0 ){
    //   StudentStore.fetchRecords()
    // }
  };

  handleClassType = e => {
    store.st_class_name = _.filter(ClassStore.records, {'type': e});
  };

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    console.log('formData', formData);
    http.post('/api/homework/record/add/', formData)
      .then(res => {
        history.push('/homework/result');
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <div className={styles.container}>
        <span className={styles.title}>马哥教育作业提交</span>
        <div className={styles.content}>
          <Form>
             <Form.Item {...itemLayout} required label="培训课程">
                  {getFieldDecorator('at_class_name', {initialValue: info['at_class_name']})(
                    <Select placeholder="请选择培训课程" showSearch onSelect={this.handleClassType}>
                      {ClassStore.type.map(item => (
                        <Select.Option key={item} value={item}>{item}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
             <Form.Item {...itemLayout} required label="课程期数">
                  {getFieldDecorator('student_class_id', {initialValue: info['student_class_id']})(
                    <Select placeholder="请选择课程期数" showSearch>
                      {store.st_class_name.map(item => (
                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

            {/*<Form.Item {...itemLayout} required label="班级名称">*/}
            {/*  {getFieldDecorator('student_class_id', {initialValue: info['student_class_id']})(*/}
            {/*    <Select placeholder="请选择班级" showSearch>*/}
            {/*      {ClassStore.records.map(item => (*/}
            {/*        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  )}*/}
            {/*</Form.Item>*/}
            <Form.Item {...itemLayout} required label="姓名/QQ/手机">
              {getFieldDecorator('student_name_qq')(
                <Input placeholder="请输入名字或者QQ或者手机" />
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="周次">
              {getFieldDecorator('week', {initialValue: info['week']})(
                <Select placeholder="请选择周次">
                  {_.range(1,28).map(item=> (
                    <Select.Option key={item} value={item}>第{item}周</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="作业链接">
              {getFieldDecorator('detail', { initialValue: info['detail']})(
                <Input.TextArea rows={4} placeholder="请输入作业链接"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} label="备注信息">
              {getFieldDecorator('desc', {initialValue: info['desc']})(
                <Input.TextArea placeholder="请输入备注信息"/>
              )}
            </Form.Item>
          </Form>
        </div>
        <Button type="primary" loading={this.state.loading} onClick={this.handleSubmit}>提交</Button>
      </div>
    )
  }
}

export default Form.create()(ComForm)
