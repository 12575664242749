import React from 'react';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import { SearchForm, AuthDiv, AuthCard  } from 'components';
import ComTable from './Table';
import store from './store';

export default observer(function () {
  return (
    <AuthCard auth="student.work.view">
      <SearchForm>
        <SearchForm.Item span={8} title="学员姓名">
          <Input allowClear onChange={e => store.f_name = e.target.value} placeholder="班级"/>
        </SearchForm.Item>
        <SearchForm.Item span={8} title="公司名称">
          <Input allowClear onChange={e => store.f_company = e.target.value} placeholder="公司名称"/>
        </SearchForm.Item>
        <SearchForm.Item span={8}>
          <Button type="primary" icon="sync" onClick={store.fetchRecords}>刷新</Button>
        </SearchForm.Item>
      </SearchForm>
      <AuthDiv auth="student.work.add" style={{ marginBottom: 16 }}>
        <Button type="primary" icon="plus" onClick={() => store.showForm()}>新建工作经历</Button>
      </AuthDiv>
      <ComTable/>
    </AuthCard>
  )
})
