import React from 'react';
import { observer } from 'mobx-react';
import {Modal, Form, Input, message, Select, Col, Row} from 'antd';
import http from 'libs/http';
import store from './store';
import ClassStore from "../class/store";
import _ from "lodash";

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      type: null,
      body: store.record['body'],
      know_by_info: ['百度搜索', '马哥官网', '知乎', '微信群', 'QQ群', '微信朋友圈', '朋友推荐', '腾讯课堂', '网易云课堂', '其他']

    }
  }

  componentDidMount() {
    if (ClassStore.records.length === 0 ){
      ClassStore.fetchRecords();
    }
  }

  handleClassType = e => {
    store.st_class_name = _.filter(ClassStore.records, {'type': e});
  };

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    formData['body'] = this.state.body;
    http.post('/api/student/', formData)
      .then(res => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };


  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 16}
    };
    return (
      <Modal
        visible
        width="67%"
        maskClosable={false}
        title={store.record.id ? '编辑学员信息' : '添加学员'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Row>
            <Col span={12}>
              <Form.Item {...itemLayout} required label="学习课程">
                {getFieldDecorator('class_type', {initialValue: info['class_type']})(
                  <Select placeholder="请选择培训课程" showSearch onSelect={this.handleClassType}>
                    {ClassStore.type.map(item => (
                      <Select.Option key={item} value={item}>{item}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...itemLayout} required label="课程期数">
                {getFieldDecorator('at_class_id', {initialValue: info['at_class_id']})(
                  <Select placeholder="请选择课程期数" showSearch>
                    {store.st_class_name.map(item => (
                      <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            <Form.Item {...itemLayout} required label="学员姓名">
              {getFieldDecorator('name', {initialValue: info['name']})(
                <Input placeholder="请输入学员姓名"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="学员年龄">
              {getFieldDecorator('age', {initialValue: info['age']})(
                <Input placeholder="请输入学员年龄"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="学员性别">
              {getFieldDecorator('sex', {initialValue: info['sex']})(
                <Input placeholder="请输入学员性别"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="学员所在地">
              {getFieldDecorator('address', {initialValue: info['address']})(
                <Input placeholder="请输入学员所在地"/>
              )}
            </Form.Item>

            <Form.Item {...itemLayout} required label="学员学历">
              {getFieldDecorator('education', {initialValue: info['education']})(
                <Input placeholder="请输入学员学历"/>
              )}
            </Form.Item>

            <Form.Item {...itemLayout} required label="学员授权号码">
              {getFieldDecorator('is_authorized', {initialValue: info['is_authorized']})(
                <Input placeholder="是否授权"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="学员授权号码">
              {getFieldDecorator('authorized_num', {initialValue: info['authorized_num']})(
                <Input placeholder="请输入学员授权号码"/>
              )}
            </Form.Item>
              <Form.Item {...itemLayout} label="支付渠道">
                  {getFieldDecorator('pay_channel', {initialValue: info['pay_channel']})(
                    <Input placeholder="请输入支付渠道"/>
                  )}
                </Form.Item>
              <Form.Item {...itemLayout} label="邮箱地址">
                {getFieldDecorator('email_address', {initialValue: info['desc']})(
                    <Input.TextArea placeholder="请输入邮箱地址"/>
                )}
              </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...itemLayout} required label="学员毕业学校">
              {getFieldDecorator('school', {initialValue: info['school']})(
                <Input placeholder="请输入学员毕业学校"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="学员目前岗位">
              {getFieldDecorator('job', {initialValue: info['job']})(
                <Input placeholder="请输入学员目前岗位"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="学员目前待遇">
              {getFieldDecorator('salary_now', {initialValue: info['salary_now']})(
                <Input placeholder="请输入学员目前待遇"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="期望待遇">
              {getFieldDecorator('salary_expect', {initialValue: info['salary_expect']})(
                <Input placeholder="请输入学员期望待遇"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="学员QQ">
              {getFieldDecorator('qq', {initialValue: info['qq']})(
                <Input placeholder="请输入学员QQ"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="学员手机">
              {getFieldDecorator('mobile', {initialValue: info['mobile']})(
                <Input placeholder="请输入学员手机"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="选择马哥的理由">
              {getFieldDecorator('join_reason', {initialValue: info['join_reason']})(
                <Input.TextArea placeholder="请输入马哥教育的理由" />
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="课程顾问">
              {getFieldDecorator('counselor', {initialValue: info['counselor']})(
                <Input placeholder="请输入课程顾问"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="获知课程途径">
              {getFieldDecorator('know_by', {initialValue: info['know_by']})(
                <Select placeholder="请选择获取途径" allowClear>
                  {this.state.know_by_info.map(item=>(
                    <Select.Option key={item} value={item}>{item}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} label="备注信息">
              {getFieldDecorator('desc', {initialValue: info['desc']})(
                <Input.TextArea placeholder="请输入模板备注信息"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} label="收件地址">
              {getFieldDecorator('delivery_address', {initialValue: info['delivery_address']})(
                  <Input.TextArea placeholder="请输入收件地址"/>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)
