import React from 'react';
import {observer} from 'mobx-react';
import {List} from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import store from './store'

@observer
class StudentCoin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  render() {
    const Item = List.Item;
    const Brief = Item.Brief;

    return (
      <div>
        <List.Item>
          <div >
            <List >
              <div>
                {store.history.map(item => (
                  <Item multipleLine extra={item.money}>
                  {item.item} <Brief>{item.happen_time}</Brief>
                  </Item>
                ))}
              </div>
            </List>
          </div>
        </List.Item>
      </div>
    );
  }
}

export default StudentCoin
