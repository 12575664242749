import React from 'react';
import {observer} from 'mobx-react';
import {Modal, Form, Input, DatePicker, message} from 'antd';
import http from 'libs/http';
import store from './store';
import moment from 'moment'

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      company_data: [],
      company: null,
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['start_time'] = formData['start_time'].format('YYYY-MM-DD');
    formData['end_time'] = formData['end_time'].format('YYYY-MM-DD');
    formData['id'] = store.record.id;
    console.log('formData', formData);
    http.post('/api/student/work/', formData)
      .then(res => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };


  fetch = (value, callback) => {
    // currentValue = value;
  };
  handleSearch = value => {
    if (value) {
      // fetch(value, company_data => this.setState({company}));

    } else {
      this.setState({company_data: []});
    }
  };

  handleChange = value => {
    this.setState({'company': value});
  };


  render() {
    // const options = this.state.company_data.map(d => <Select.Option key={d.value}>{d.text}</Select.Option>);
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={800}
        maskClosable={false}
        title={store.record.id ? '编辑学员经历' : '新建学员经历'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="学员姓名">
            {getFieldDecorator('student_name', {initialValue: info['student_name']})(
              <Input placeholder="请输入学员名称"/>
            )}
          </Form.Item>
          {/*<Form.Item {...itemLayout} required label="公司名称">*/}
          {/*    {getFieldDecorator('company', {initialValue: info['company']})(*/}
          {/*      <Select showSearch value={this.state.company} placeholder="请选择公司名称"*/}
          {/*              style={this.props.style} defaultActiveFirstOption={false}*/}
          {/*              showArrow={false} filterOption={false}*/}
          {/*              // onSearch={this.handleSearch}*/}
          {/*              // onChange={this.handleChange} notFoundContent={null}*/}
          {/*      >*/}
          {/*        {options}*/}
          {/*        )}*/}
          {/*      </Select>*/}
          {/*    )}*/}
          {/*</Form.Item>*/}

          <Form.Item {...itemLayout} required label="公司名称">
            {getFieldDecorator('company', {initialValue: info['company']})(
              <Input placeholder="请输入公司名称"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="进入时间">
            {getFieldDecorator('start_time', {
              initialValue: info['start_time'] ? moment(info['start_time']) : undefined,
              rules: [{type: 'object', required: true, message: '进入时间'}],
            })(<DatePicker/>)}
          </Form.Item>
          <Form.Item {...itemLayout} required label="离开时间">
            {getFieldDecorator('end_time', {
              initialValue: info['end_time'] ? moment(info['end_time']) : undefined,
              rules: [{type: 'object', required: true, message: '离开时间'}],
            })(<DatePicker/>)}
          </Form.Item>
          <Form.Item {...itemLayout} label="备注信息">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input.TextArea placeholder="请输入备注信息"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)
