import React from "react";
import { observer } from "mobx-react";
import { Input, Button } from "antd";
import { SearchForm, AuthDiv, AuthCard } from "components";
import ComTable from "./Table";
import store from "./store";

export default observer(function () {
  return (
    <AuthCard auth="student.class.view">
      <SearchForm>
        <SearchForm.Item span={8} title="顾问名字">
          <Input
            allowClear
            onChange={(e) => (store.f_name = e.target.value)}
            placeholder="输入顾问名字"
          />
        </SearchForm.Item>

        <SearchForm.Item span={8}>
          <Button type="primary" icon="sync" onClick={store.fetchRecords}>
            刷新
          </Button>
        </SearchForm.Item>
      </SearchForm>
      <AuthDiv auth="student.class.add" style={{ marginBottom: 16 }}>
        <Button type="primary" icon="plus" onClick={() => store.showForm()}>
          新建班级
        </Button>
      </AuthDiv>
      <ComTable />
    </AuthCard>
  );
});
