import React from 'react';
import { observer } from 'mobx-react';
import {Modal, Form, Input, message, Select} from 'antd';
import http from 'libs/http';
import store from './store';

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      coin_type: ['班委奖励','作业奖励','结业奖励','文学奖励','推荐奖励','贡献奖励','参与活动', '学费折合','报名奖励', '学霸奖励'],
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    http.post('/api/student/coin/', formData)
      .then(res => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };


  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 16}
    };
    return (
      <Modal
        visible
        width={800}
        maskClosable={false}
        title={store.record.id ? '编辑积分' : '充值积分'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="班级名称">
            {getFieldDecorator('class_name', {initialValue: info['class_name']})(
             <Input placeholder="班级名称" disabled />
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="学员姓名">
            {getFieldDecorator('name', {initialValue: info['name']})(
              <Input placeholder="请输入学员姓名" disabled />
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="学员微信">
            {getFieldDecorator('wechat', {initialValue: info['wechat']})(
              <Input placeholder="学员微信" disabled />
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="充值金额">
            {getFieldDecorator('coin', )(
              <Input prefix="￥" suffix="积分" />
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="充值类别">
            {getFieldDecorator('coin_item', {initialValue: info['coin_item']})(
              <Select placeholder="请选择充值类别" showSearch >
                {this.state.coin_type.map(item => (
                  <Select.Option key={item} value={item}>{item}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} label="备注信息">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input.TextArea placeholder="请输入模板备注信息"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)
