import { makeRoute } from "../../libs/router";
import RecordIndex from './record/index';
import PlanIndex from './plan/index';
import RecordAdd from './record/AddHomeWrok';



export default [
  makeRoute('/plan', PlanIndex),
  makeRoute('/record', RecordIndex),
  makeRoute('/add', RecordAdd),
]
