import React from "react";
import { observer } from "mobx-react";
import { Input, Button, Select, DatePicker } from "antd";
import { SearchForm, AuthDiv, AuthCard } from "components";
import ComTable from "./Table";
import store from "./store";
import ClassStore from "../class/store";
import _ from "lodash";
import moment from 'moment';


const { RangePicker } = DatePicker;

// export default observer(function () {
@observer
class StudentIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
  }

  // componentDidMount() {
  //   if (ClassStore.records.length === 0) {
  //     ClassStore.fetchRecords()
  //   }
  // }
  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  handleClassType = (e) => {
    store.st_class_name = _.filter(ClassStore.records, { type: e });
  };

  render() {
    return (
      <AuthCard auth="student.info.view">
        <SearchForm>
          <SearchForm.Item span={6} title="课程名称">
            <Select
              allowClear
              onChange={(v) => (store.f_class_type = v)}
              onSelect={this.handleClassType}
              placeholder="请选择课程名称"
            >
              {ClassStore.type.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </SearchForm.Item>
          <SearchForm.Item span={6} title="课程期数">
            <Select
              allowClear
              onChange={(v) => (store.f_class_id = v)}
              placeholder="请选择课程名称"
            >
              {store.st_class_name.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </SearchForm.Item>
          <SearchForm.Item span={6} title="学员姓名">
            <Input
              allowClear
              onChange={(e) => (store.f_name = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="学员学历">
            <Input
              allowClear
              onChange={(e) => (store.f_education = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="所在地">
            <Input
              allowClear
              onChange={(e) => (store.f_address = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          {/*<a style={{ marginLeft: 8, fontSize: 12 }} onClick={this.toggle}>*/}
          {/*  Collapse <Icon type={this.state.expand ? 'up' : 'down'} />*/}
          {/*</a>*/}
          <SearchForm.Item span={6} title="学员手机">
            <Input
              allowClear
              onChange={(e) => (store.f_mobile = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="学员QQ">
            <Input
              allowClear
              onChange={(e) => (store.f_qq = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="目前岗位">
            <Input
              allowClear
              onChange={(e) => (store.f_job = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="目前年薪">
            <Input
              allowClear
              onChange={(e) => (store.f_salary_now = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="期望年薪">
            <Input
              allowClear
              onChange={(e) => (store.f_salary_expect = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="获取途径">
            <Input
              allowClear
              onChange={(e) => (store.f_know_by = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="邮箱地址">
            <Input
                allowClear
                onChange={(e) => (store.f_email_address = e.target.value)}
                placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="选择理由">
            <Input
              allowClear
              onChange={(e) => (store.f_join_reason = e.target.value)}
              placeholder="请输入"
            />
          </SearchForm.Item>
          <SearchForm.Item span={8} title="年龄范围">
            <Input.Group compact>
              <Input
                allowClear
                style={{ width: 80, textAlign: "center" }}
                onChange={(e) => (store.f_age_start = e.target.value)}
                placeholder="最小"
              />
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: "none",
                }}
                placeholder="~"
                disabled
              />
              <Input
                allowClear
                className="site-input-right"
                onChange={(e) => (store.f_age_end = e.target.value)}
                style={{ width: 80, textAlign: "center" }}
                placeholder="最大"
              />
            </Input.Group>
          </SearchForm.Item>
          <SearchForm.Item span={8} title="提交时间">
          <RangePicker ranges={{
            今日: [moment(), moment()],
            '本月': [moment().startOf('month'), moment().endOf('month')],
          }}
          onChange={store.changeSelectdate}
          />
          </SearchForm.Item>

          <SearchForm.Item span={6}>
            <Button type="primary" icon="sync" onClick={store.handleSearch}>
              刷新
            </Button>
          </SearchForm.Item>
        </SearchForm>
          <AuthDiv auth="student.info.add" style={{marginBottom: 16}}>
            <Button type="primary" icon="plus" onClick={() => store.showForm()}>
              添加学员
            </Button>
          <Button style={{marginLeft: 20}}  type="primary" icon="download">
            <a
              style={{ color: "#FFFFFF" }}
              target="_blank"
              href={`/api/student/export/?x-token=${localStorage.getItem(
                "token"
              )}&name=${store.f_name}&class_type=${
                store.f_class_type
              }&class_id=${store.f_class_id}&education=${
                store.f_education
              }&job=${store.f_job}&mobile=${store.f_mobile}&qq=${
                store.f_qq
              }&salary_now=${store.f_salary_now}&salary_expect=${
                store.f_salary_expect
              }&age_start=${store.f_age_start}&age_end=${
                store.f_age_end
              }&know_by=${store.f_know_by}&date_start=${store.f_date_start}&date_stop=${store.f_date_stop}&join_reason=${
                store.f_join_reason
              }&address=${store.f_address}`}
            >
              导出
            </a>
          </Button>
          </AuthDiv>
        <ComTable />
      </AuthCard>
    );
  }
}

export default StudentIndex;
