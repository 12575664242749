import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Form, Input, DatePicker, message, Col, Select, Button} from 'antd';
import http from 'libs/http';
import store from './store';
import moment from 'moment'

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      type: null,
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['start_time'] = formData['start_time'] ? formData['start_time'].format('YYYY-MM-DD') : '' ;
    formData['end_time'] = formData['end_time'] ? formData['end_time'].format('YYYY-MM-DD') : '';
    formData['id'] = store.record.id;
    http.post('/api/student/class/', formData)
      .then(res => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  handleAddType = () => {
    Modal.confirm({
      icon: 'exclamation-circle',
      title: '添加课程名称',
      content: this.addTypeForm,
      onOk: () => {
        if (this.state.type) {
          store.type.push(this.state.type);
          this.props.form.setFieldsValue({'type': this.state.type})
        }
      },
    })
  };

  addTypeForm = (
    <Form>
      <Form.Item required label="课程">
        <Input onChange={val => this.setState({type: val.target.value})}/>
      </Form.Item>
    </Form>
  );

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={800}
        maskClosable={false}
        title={store.record.id ? '编辑班级' : '新建班级'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="课程名称">
            <Col span={16}>
              {getFieldDecorator('type', {initialValue: info['type']})(
                <Select placeholder="请选择课程名称">
                  {store.type.map(item => (
                    <Select.Option value={item} key={item}>{item}</Select.Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col span={6} offset={2}>
              <Button type="link" onClick={this.handleAddType}>添加课程</Button>
            </Col>
          </Form.Item>
          <Form.Item {...itemLayout} required label="班级期数">
            {getFieldDecorator('name', {initialValue: info['name']})(
              <Input placeholder="请输入班级期数"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="企业教练">
            {getFieldDecorator('technical', {initialValue: info['technical']})(
              <Input placeholder="请输入班级企业教练"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="班主任">
            {getFieldDecorator('teacher', {initialValue: info['teacher']})(
              <Input placeholder="请输入班级班主任"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="开班时间">
            {getFieldDecorator('start_time', {
              initialValue: info['start_time'] ? moment(info['start_time']): undefined,
              rules: [{type: 'object', required: true, message: '请选择开班时间'}],
            })(<DatePicker/>)}
          </Form.Item>
          <Form.Item {...itemLayout} required label="结班时间">
            {getFieldDecorator('end_time', {
              initialValue: info['end_time'] ? moment(info['end_time']) : undefined,
              rules: [{type: 'object', required: true, message: '请选择结班时间'}],
            })(<DatePicker/>)}
          </Form.Item>
          <Form.Item {...itemLayout} label="备注信息">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input.TextArea placeholder="请输入备注信息"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)
