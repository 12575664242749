import React from 'react';
import { observer } from 'mobx-react'
import { Modal, Table, Tag} from 'antd';
import store from './store';

@observer
class CoinDetail extends React.Component {

    columns = [
        {
        title: '姓名',
        dataIndex: 'student__name'
    },{
        title: '当前积分',
        dataIndex: 'student__coin'
    },
    {
        title: '积分类别',
        dataIndex: 'item'
    }
    ,{
        title: '交易金额',
        render: info => {
            if (info['type'] === 1){
                return <Tag color="#87d068" style={{cursor: 'pointer'}} > + {info['money']}</Tag>
            }
            else {
                return <Tag color="#f50" style={{cursor: 'pointer'}} > - {info['money']}</Tag>
            }
        }
    },{
        title: '交易时间',
        dataIndex: 'happen_time'
    },{
        title: '操作人',
        dataIndex: 'operator'
    },{
        title: '备注信息',
        dataIndex: 'desc'
    }];

    render() {
        const info = store.history;
        return (
          <Modal
            visible
            width={800}
            maskClosable={false}
            title={'积分明细'}
            onOk={()=>store.detailVisible=false}
            onCancel={() => store.detailVisible = false}>
              <React.Fragment>
                  <Table rowKey="id" loading={store.historyFetching} dataSource={info} columns={this.columns}/>
              </React.Fragment>
          </Modal>
        )
    }
}

export default CoinDetail

