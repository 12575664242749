import { observable } from "mobx";
import http from 'libs/http';
import history from "libs/history";


class Store {
  @observable records = [];
  @observable company = [];
  @observable record = {};
  @observable isFetching = false;
  @observable formVisible = false;

  @observable f_name;
  @observable f_mobile;
  @observable f_express_num;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/student/express/')
      .then((res) => {
        this.records = res;
      })
      .finally(() => this.isFetching = false)
  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info;
    console.log('this.record', this.record);
  }

  searchExpress = (info = {}) => {
    http.get('/api/app/express/',
      {params: {'mobile': info['mobile']}}).then((res)=> {
      this.express_info = res;
      console.log('express_info', res);
      history.push('/student/express_info');
    }).finally()
  };

}

export default new Store()
