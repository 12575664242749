import React from 'react';
import {observer} from 'mobx-react';
import {Modal, Form, Input, DatePicker, message} from 'antd';
import http from 'libs/http';
import store from './store';
import moment from 'moment'

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      company_data: [],
      company: null,
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    console.log('formData', formData);
    http.post('/api/student/express/', formData)
      .then(res => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };


  fetch = (value, callback) => {
    // currentValue = value;
  };
  handleSearch = value => {
    if (value) {
      // fetch(value, company_data => this.setState({company}));

    } else {
      this.setState({company_data: []});
    }
  };

  handleChange = value => {
    this.setState({'company': value});
  };


  render() {
    // const options = this.state.company_data.map(d => <Select.Option key={d.value}>{d.text}</Select.Option>);
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={800}
        maskClosable={false}
        title={store.record.id ? '编辑学员快递' : '添加学员快递'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>

        <Form.Item {...itemLayout} required label="快递公司">
            {getFieldDecorator('express', {initialValue: info['express']})(
              <Input placeholder="快递公司"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="快递单号">
            {getFieldDecorator('express_num', {initialValue: info['express_num']})(
              <Input placeholder="快递单号"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="收件姓名">
            {getFieldDecorator('username', {initialValue: info['username']})(
              <Input placeholder="请输入学员手机号"/>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} required label="收件号码">
            {getFieldDecorator('mobile', {initialValue: info['mobile']})(
              <Input placeholder="请输入学员手机号"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="邮寄物品">
            {getFieldDecorator('goods', {initialValue: info['goods']})(
              <Input placeholder="输入邮寄的物品，例如书籍/鼠标垫"/>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} label="备注信息">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input.TextArea placeholder="请输入备注信息"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)
