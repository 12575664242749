import { makeModuleRoute } from "./libs/router";

import welcomeRoues from './pages/welcome/routes';
import homeRoutes from './pages/home/routes';
import systemRoutes from './pages/system/routes';
import studentRoutes from './pages/student/routes';
import gradeRoutes from './pages/grade/routes';
import homeworkRoutes from './pages/homework/routes';
import feedbackRoutes from './pages/feedback/routes';


export default [
  makeModuleRoute('/welcome', welcomeRoues),
  makeModuleRoute('/home', homeRoutes),
  makeModuleRoute('/system', systemRoutes),
  makeModuleRoute('/student', studentRoutes),
  makeModuleRoute('/grade', gradeRoutes),
  makeModuleRoute('/homework', homeworkRoutes),
  makeModuleRoute('/feedback', feedbackRoutes),
]
