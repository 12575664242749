import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Form, Input, Select, message } from 'antd';
import http from 'libs/http';
import store from './store';
import ClassStore from '../student/class/store'

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    if (ClassStore.records.length === 0 ){
      ClassStore.fetchRecords()
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    http.post('/api/grade/', formData)
      .then(res => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={800}
        maskClosable={false}
        title={store.record.id ? '编辑评分' : '新建评分'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="班级名称">
            {getFieldDecorator('student_class_id', {initialValue: info['student_class_id']})(
              <Select placeholder="请选择班级">
                {ClassStore.records.map(item => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="评分类型">
            {getFieldDecorator('type', {initialValue: info['type']})(
              <Select placeholder="请评分类型班级">
                  <Select.Option key={1} value={1}>开班评分</Select.Option>
                  <Select.Option key={2} value={2}>结业评分</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="教练分数">
            {getFieldDecorator('technical', {initialValue: info['technical']})(
              <Input placeholder="请输入教练分数"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="班主任分数">
            {getFieldDecorator('teacher', {initialValue: info['teacher']})(
              <Input placeholder="请输入班主任分数"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="学员建议">
            {getFieldDecorator('suggest', {initialValue: info['suggest']})(
              <Input.TextArea rows={4} placeholder="请输入学员建议"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="备注信息">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input.TextArea placeholder="请输入备注信息"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)
