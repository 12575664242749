import { observable } from "mobx";
import http from 'libs/http';
import ClassStore from "../class/store";

class Store {
  @observable records = [];
  @observable types = [];
  @observable know = [];
  @observable record = {};
  @observable recordTotal = '';
  @observable isFetching = false;
  @observable formVisible = false;
  @observable detailVisible = false;
  @observable st_class_name = [];
  @observable class_option = [];
  @observable counselors = [];
  @observable mobile_counselors = [];

  @observable f_name = '';
  @observable f_class_type = '';
  @observable f_class_id = '';
  @observable f_education = '';
  @observable f_job = '';
  @observable f_mobile = '';
  @observable f_qq = '';
  @observable f_salary_now = '';
  @observable f_salary_expect = '';
  @observable f_age_start = '';
  @observable f_age_end = '';
  @observable f_know_by = '';
  @observable f_join_reason = '';
  @observable f_address = '';
  @observable f_created_at = [];
  @observable f_date_start = ''
  @observable f_date_stop = '';
  @observable f_email_address = '';



  fetchRecords = (page=1, page_size=10) => {
    this.isFetching = true;
    console.log('this.f_add_date', this.f_created_at);
    http.get('/api/student/',
      {params: {
        page: page,
          page_size: page_size,
          name: this.f_name,
          class_type: this.f_class_type,
          class_id: this.f_class_id,
          education: this.f_education,
          job: this.f_job,
          mobile: this.f_mobile,
          qq: this.f_qq,
          salary_now: this.f_salary_now,
          salary_expect: this.f_salary_expect,
          age_start: this.f_age_start,
          age_end: this.f_age_end,
          know_by: this.f_know_by,
          join_reason: this.f_join_reason,
          address: this.f_address,
          created_at: this.f_created_at,
          date_start: this.f_date_start,
          date_stop: this.f_date_stop,
          email_address: this.f_email_address,
      }
      }).then(res => {
        this.records = res.data;
        this.recordTotal = res.total;
      }).finally(() => this.isFetching = false);
  };

  handlePage = (info) => {
    this.fetchRecords(info.current, info.pageSize);
  };

  handleSearch = (info) => {
    this.fetchRecords(info.current, info.pageSize);

  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info;
    this.st_class_name = ClassStore.records;

  };
  showDetail = (info = {}) => {
    this.detailVisible = true;
    this.record = info
  };
  changeSelectdate = (dates, dateStr) => {
    this.f_date_start = dateStr[0];
    this.f_date_stop = dateStr[1];

  }

}

export default new Store()
