import React from 'react';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import { SearchForm, AuthCard } from 'components';
import ComTable from './Table';
import store from './store';

export default observer(function () {
  return (
    <AuthCard auth="grade.grade.view">
      <SearchForm>
        <SearchForm.Item span={8} title="班级">
          <Input allowClear onChange={e => store.f_class = e.target.value} placeholder="班级"/>
        </SearchForm.Item>
        <SearchForm.Item span={8}>
          <Button type="primary" onClick={() => store.fetchRecords()}>刷新</Button>
        </SearchForm.Item>
      </SearchForm>
      <div style={{marginBottom: 16}}>
        <Button type="primary" icon="plus" onClick={() => store.showForm()}>新建评分</Button>
      </div>
      <ComTable/>
    </AuthCard>
  )
})
