import React from 'react';
import { observer } from 'mobx-react';
import {Table, Divider, Tag} from 'antd';
import ComForm from './Form';
import store from './store';
import { LinkButton } from "components";
import CoinDetail from './Detail';

@observer
class ComTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '班级',
    dataIndex: 'class_name',
  }, {
    title: '姓名',
    dataIndex: 'name',
  }, {
    title: '微信',
    dataIndex: 'wechat'
  },{
    title: '手机',
    dataIndex: 'mobile'
  },{
    title: 'QQ',
    dataIndex: 'qq'
  },  {
    title: '积分数',
    render: info => {
      return <Tag color="#87d068" style={{cursor: 'pointer'}} >{info['coin']}</Tag>
    }
  },{
    title: '操作',
    render: info => (
      <span>
        <LinkButton auth="student.coin.history"  onClick={() => store.showHistoryInner(info)}>明细</LinkButton>
        <Divider type="vertical"/>
        <LinkButton auth="student.coin.add"  onClick={() => store.showForm(info)}>充值</LinkButton>
      </span>
    )
  }];

  render() {
    let data = store.records;
    return (
      <React.Fragment>
        <Table rowKey="id" loading={store.isFetching} dataSource={data} columns={this.columns}
               pagination={{'total': store.recordTotal, showTotal: total => `总数量 ${total}`}}
               onChange={store.handlePage}/>
        {store.formVisible && <ComForm/>}
        {store.detailVisible && <CoinDetail/>}
      </React.Fragment>
    )
  }
}

export default ComTable
