import React from 'react';
import { observer } from 'mobx-react';
import { List, Card } from 'antd';
import store from './store';
import classStore from '../student/class/store'


@observer
class ComTable extends React.Component {
  componentDidMount() {
    store.fetchRecords();
  }
  render() {
    let data = classStore.records;
    // if (store.f_class) {
    //   data = data.filter(item => item['class_name'].toLowerCase().includes(store.f_class.toLowerCase()))
    // }
    return (
      <div>
        <List grid={{gutter: 16, column: 4}} dataSource={data} loading={store.isFetching} renderItem={item => (
          <List.Item >
            <Card  title={'班级：' + item.name} onClick={() => store.showClassStudent(item)}>
              学员数量：{item.class_count} 人
            </Card>
          </List.Item>
        )}
        />
      </div>
    )
  }
}

export default ComTable
