import React from 'react';
import {observer} from 'mobx-react';
import {List, InputItem, Button, Toast} from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import {Form} from "antd";
import store from './store'
import express from '.';
import './express.css';

@observer
class ExpressSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  showToast = (data) => {
    Toast.info(data, 2);
  };

  handleSearch = () => {
    this.props.form.validateFields({force: true}, (error) => {
      if (!error) {
        let express_data = this.props.form.getFieldsValue();
        if (!express_data['mobile']) {
          this.showToast('手机号不能为空！');
          return;
        }
        store.searchExpress(express_data);
        console.log('student_data', express_data);
      }
    });
  };


  render() {
    const {getFieldProps} = this.props.form;
    return (<form>
        <div>
          <List.Item>
            <div style={{width: '100%',  fontSize: '28px', textAlign: 'center'}}>
              马哥教育礼品快递信息查询
            </div>
          </List.Item>
          <List>
            <InputItem
              {...getFieldProps('mobile')}
              title="手机"
              type="phone"
              placeholder="请输入手机">手机
            </InputItem>
            <div style={{marginTop: "10px", marginBottom: "10px"}}>
              <span><b>【查询说明】</b></span>
              <span>输入收件人的手机号码查询快递信息。</span>
            </div>
            <div>
              <Button type="primary" loading={this.state.loading} onClick={this.handleSearch}>查询</Button>
            </div>
          </List>
        </div>
      </form>
    );
  }
}

export default Form.create()(ExpressSearch)
