import React from 'react';
import {observer} from 'mobx-react';
import {DatePicker, List, Picker, InputItem, Button, Toast} from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import {Form} from "antd";
import http from "../../../libs/http";
import history from "../../../libs/history";
import store from './store';
import ClassStore from '../../student/class/store';
import wx from '../../../layout/mg-wx.png';
import _ from 'lodash'
import moment from 'moment';

// const nowTimeStamp = Date.now();
// const now = new Date(nowTimeStamp);

@observer
class AddStudentMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      counselor_info: [],
      know_by_info: [
        { label: '百度搜索', value: '百度搜索'},
        { label: '马哥官网', value: '马哥官网'},
        { label: '知乎', value: '知乎'},
        { label: '微信群', value: '微信群'},
        { label: 'QQ群', value: 'QQ群'},
        { label: '微信朋友圈', value: '微信朋友圈'},
        { label: '腾讯课堂', value: '腾讯课堂'},
        { label: '朋友推荐', value: '朋友推荐'},
        { label: '网易云课堂', value: '网易云课堂'},
        { label: '其他', value: '其他'}],
      know: null,
      pay_time: '',
      sexValue: '男',
      classNameValue: '',
    }
  }

  componentDidMount() {
    if (ClassStore.records.length === 0) {
      ClassStore.fetchRecords()
    }

    this.fetchCounselorRecords();
  }



  fetchCounselorRecords = () => {
    this.isFetching = true;
    let counselor_info = [];
    http.get('/api/app/counselor/')
      .then((res) => {
        console.log('123', res);
        for (let i = 0; i < res.length; i++) {
          counselor_info.push({label: res[i].name, value: res[i].name})
        }
        this.setState({counselor_info: counselor_info});
      })
      .finally(() => this.isFetching = false)
  };


  handleClassType = e => {
    this.setState({classNameValue: e});
    store.st_class_name = _.filter(ClassStore.records, {'type': e[0]});
  };
  showLoading = () => {
    Toast.loading('正在提交', 0);
  };

  showToast = (data) => {
    Toast.info(data, 2);
  };

  handleSubmit = () => {
    this.props.form.validateFields({force: true}, (error) => {
      if (!error) {
        let student_data = this.props.form.getFieldsValue();

        if (!student_data['sex']) {
          this.showToast('请选择性别');
          return;
        } else if (!student_data['at_class_id']) {
          this.showToast('请选择课程期数');
          return;
        } else if (!student_data['counselor']) {
          this.showToast('请选择课程顾问');
          return;
        } else if (!student_data['know_by']) {
          this.showToast('请选择获取渠道');
          return;
        }
        // this.setState({loading: true});
        student_data.pay_time = moment(this.state.pay_time).format('YYYY-MM-DD');
        student_data.sex = student_data['sex'][0];
        student_data.at_class_id = student_data['at_class_id'][0];
        student_data.counselor = student_data['counselor'][0];
        student_data.know_by = student_data['know_by'][0];

        console.log('student_data', student_data);
        this.showLoading();
        http.post('/api/student/add/', student_data)
          .then(res => {
            Toast.hide();
            this.setState({loading: false});
            history.push('/student/result');
          }, () => Toast.hide())
      } else {
        return '';
      }
    });
  };


  render() {
    // const {getFieldDecorator} = this.props.form;
    const {getFieldProps} = this.props.form;
    let at_class_name_option = [];
    let st_class_id_option = [];
    const sex_option = [
      { label: '男', value: '男'},
      { label: '女', value: '女'},
    ];
    ClassStore.type.map(item => (
      at_class_name_option.push({'value': item, 'label': item})
    ));

    store.st_class_name.map(item => (
      st_class_id_option.push({'value': item.id, 'label': item.name})
    ));
    return (<form>
        <div >
          <List.Item>
            <div style={{width: '100%', color: '#108ee9', textAlign: 'center'}}>
              马哥教育学员报名信息登记表
            </div>
          </List.Item>

          <List>
            <Picker data={at_class_name_option} cols={1} {...getFieldProps('at_class_name')}
                    value={this.state.classNameValue}
                    onChange={this.handleClassType} >
              <List.Item arrow="horizontal">请选择培训课程</List.Item>
            </Picker>
            <Picker data={st_class_id_option} cols={1} {...getFieldProps('at_class_id')} >
              <List.Item arrow="horizontal">请选择课程期数</List.Item>
            </Picker>
            <span>因课程授权需要绑定身份证,请务必提供与身份证一致得真实姓名</span>
            <InputItem
              {...getFieldProps('name')}
              title="姓名"
              placeholder="因课程授权需要绑定身份证,请务必提供与身份证一致得真实姓名">姓名
            </InputItem>
            <Picker data={sex_option} cols={1} {...getFieldProps('sex')}>
              <List.Item arrow="horizontal">性别</List.Item>
            </Picker>
            <InputItem
              {...getFieldProps('age')}
              title="年龄"
              type="number"
              placeholder="请输入您的年龄">年龄
            </InputItem>
            <InputItem
              {...getFieldProps('qq')}
              title="QQ"
              type="number"
              placeholder="请输入QQ">QQ
            </InputItem>
            <InputItem
              {...getFieldProps('mobile')}
              title="手机"
              type="phone"
              placeholder="请输入手机">手机
            </InputItem>
            <InputItem
              {...getFieldProps('address')}
              title="所在地"
              placeholder="请输入所在地">所在地
            </InputItem>
            <InputItem
              {...getFieldProps('education')}
              title="学历"
              placeholder="请输入学历">学历
            </InputItem>
            <InputItem
              {...getFieldProps('school')}
              title="毕业学校"
              placeholder="请输入毕业学校">毕业学校
            </InputItem>
            <InputItem
              {...getFieldProps('job')}
              title="目前岗位"
              placeholder="请输入目前岗位">目前岗位
            </InputItem>
            <InputItem
              {...getFieldProps('salary_now')}
              title="目前年薪"
              placeholder="请输入目前年薪">目前年薪
            </InputItem>
            <InputItem
              {...getFieldProps('salary_expect')}
              title="期望年薪"
              placeholder="请输入期望年薪">期望年薪
            </InputItem>
            <Picker data={this.state.counselor_info} cols={1} {...getFieldProps('counselor')} >
              <List.Item arrow="horizontal">请选择课程顾问</List.Item>
            </Picker>
            <DatePicker
              mode="date"
              title="支付时间"
              extra="Optional"
              value={this.state.pay_time}
              onChange={pay_time => this.setState({pay_time})}>
              <List.Item arrow="horizontal">请选择支付时间</List.Item>
            </DatePicker>
            <InputItem
              {...getFieldProps('join_reason')}
              title="加入马哥教育理由"
              placeholder="加入马哥教育的理由">加入理由
            </InputItem>
            <Picker data={this.state.know_by_info} cols={1} {...getFieldProps('know_by')} >
              <List.Item arrow="horizontal">请选择获取途径</List.Item>
            </Picker>
            <InputItem
              {...getFieldProps('pay_channel')}
              title="支付渠道"
              placeholder="请输入支付渠道">支付渠道
            </InputItem>
            <InputItem
                {...getFieldProps('email_address')}
                title="邮箱地址"
                placeholder="请输入邮箱地址">邮箱地址
            </InputItem>
            <InputItem
                {...getFieldProps('delivery_address')}
                title="收件地址"
                placeholder="请输入收件地址">收件地址
            </InputItem>
            <div style={{ marginTop: "20px"}}>
              <span><b>【特别说明】</b><br/></span>
              <span>
                    1、所有内容需要认真填写，切勿错误；<br/>
                    2、有疑问的地方请咨询课程顾问；<br/>
                    3、支付定金属于占用学校座位预留资格和预习视频的费用，缴纳后不再退还；<br/>
                    4、学习费用可开具发票，学费不含税点，需另外支付税点，发票开具有效期是从报名日起3个月内，请知悉；<br/>
                    5、因课程授权需要绑定身份证,请务必提供与身份证一致得真实姓名！
                  </span>
            </div>
            <div >
              <img height="250px" width="250px" src={wx} alt="weixin" />
              <br/>
              <span>关注微信公众号，获取最新Linux和Python技术干货</span>
            </div>
          </List>
          <div>
            <Button type="primary" loading={this.state.loading} onClick={this.handleSubmit}>确认无误，提交</Button>
          </div>
        </div>
      </form>
    );
  }
}
export default Form.create()(AddStudentMobile)
