import React from 'react';
import { observer } from 'mobx-react';
import { Input, Button, Select } from 'antd';
import { SearchForm, AuthCard } from 'components';
import ComTable from './Table';
import store from './store';
import ClassStore from '../class/store'

@observer
class StudentIndex extends React.Component {
  componentDidMount() {
    if (ClassStore.records.length === 0) {
      ClassStore.fetchRecords()
    }
  }

  render() {
    return (
      <AuthCard auth="student.coin.view">
        <SearchForm>
          <SearchForm.Item span={8} title="课程名称">
            <Select allowClear  onChange={v => store.f_class_type = v} placeholder="请选择课程名称">
              {ClassStore.type.map(item => (
                <Select.Option value={item} key={item}>{item}</Select.Option>
              ))}
            </Select>
          </SearchForm.Item>
          <SearchForm.Item span={8} title="学员姓名">
            <Input allowClear onChange={e => store.f_name = e.target.value} placeholder="请输入"/>
          </SearchForm.Item>
          <SearchForm.Item span={8}>
            <Button type="primary" icon="sync" onClick={store.handleSearch}>刷新</Button>
          </SearchForm.Item>
        </SearchForm>
        <ComTable/>
     </AuthCard>
    )
  }
}

export default StudentIndex

