import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import Login from './pages/login';
import AddHomeWork from './pages/homework/record/AddHomeWrok';
import AddStudentInfo from './pages/student/info/AddStudentInfo'
import AddStudentMobile from './pages/student/info/AddStudentMobile';
import StudentResult from './pages/student/info/StudentResult'
import HomeworkResult from './pages/homework/record/HomeworkResult'
import StudentSearchCoin from './pages/student/coin/CoinSearch'
import StuCoinResult from './pages/student/coin/CoinResult'
import StuCoinHistory from './pages/student/coin/CoinHistory'
import ExpressSearch from './pages/student/express/ExpressSearch'
import ExpressResult from './pages/student/express/ExpressResult'
import Layout from './layout';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/add_homework" exact component={AddHomeWork} />
        <Route path="/add_student" exact component={AddStudentInfo} />
        <Route path="/add_student_m" exact component={AddStudentMobile} />
        <Route path="/student/result" exact component={StudentResult} />
        <Route path="/homework/result" exact component={HomeworkResult} />
        <Route path="/student/coin_search" exact component={StudentSearchCoin} />
        <Route path="/student/coin_info" exact component={StuCoinResult} />
        <Route path="/student/express_search" exact component={ExpressSearch} />
        <Route path="/student/express_info" exact component={ExpressResult} />
        <Route path="/student/coin_history" exact component={StuCoinHistory} />
        <Route component={Layout} />
      </Switch>
    );
  }
}

export default App;
