export default [
  {icon: 'desktop', title: '工作台', auth: 'home.home.view', path: '/home'},
  {
    icon: 'idcard', title: '学员管理', auth: 'student.info.view|student.coin.view|student.work.view|student.class.view', child: [
      {title: '学员信息', auth: 'student.info.view', path: '/student/info'},
      {title: '学员积分', auth: 'student.coin.view', path: '/student/coin'},
      {title: '学员工作', auth: 'student.work.view', path: '/student/work'},
      {title: '快递信息', auth: 'student.express.view', path: '/student/express'},
      {title: '班级管理', auth: 'student.class.view', path: '/student/class'},
      {title: '顾问管理', auth: 'student.counselor.view', path: '/student/counselor'},
    ]},
  {
    icon: 'solution', title: '作业管理',  auth: 'homework.plan.view|homework.record.view', child: [
      // {title: '作业安排', auth: 'homework.plan.view', path: '/homework/plan'},
      {title: '提交记录', auth: 'homework.record.view', path: '/homework/record'},
    ]},
  // {icon: 'setting', title: '回访管理', auth: 'feedback.feedback.view', path: '/feedback'},
  {icon: 'star', title: '评分管理', auth: 'grade.grade.view', path: '/grade'},
  {icon: 'setting', title: '系统管理', auth: "system.account.view|system.role.view", child: [
      {title: '账户管理', auth: 'system.account.view', path: '/system/account'},
      {title: '角色管理', auth: 'system.role.view', path: '/system/role'},
    ]
  },
]
