import React from 'react';
import {observer} from 'mobx-react';
import {Button, Result} from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import history from "../../../libs/history";
import store from './store'
import coin from '../../../layout/images/coins.svg';

@observer
class StuCoinResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  handleBack = () => {
    history.push('/student/coin_search')
  };

  handleHistoryData = () => {
    console.log(111, store.student_coin);
    store.handleTohHistory()
  };


  render() {
    const myImg = src => <img src={src} className="spe am-icon am-icon-md" alt="" />;
    return (
      <React.Fragment>
        <div >
          <Result
            img={myImg(coin)}
            title="积分数量"
            message={<div>¥ {store.student_coin['coin']} </div>}
          />
        </div>
        <div style={{margin: "10px"}}>
          {/*<span><b>【积分说明】</b></span>*/}
          <span>积分是马哥教育针对老学员推出的增值服务，用于兑换马哥教育礼品或者课程使用。</span>
          <span>激活个人积分功能，请联系班主任或者QQ295813080</span>
        </div>
        <div style={{width: '100%', color: 'red', fontSize: '25px', textAlign: 'center'}}>
          <Button icon="left" inline size="small" style={{ marginRight: '4px' }}
                  type="primary" onClick={this.handleBack}>返回</Button>
          <Button icon="search" size="small" inline type="primary" onClick={this.handleHistoryData}>积分记录</Button>
        </div>

      </React.Fragment>
    );
  }
}

export default StuCoinResult
