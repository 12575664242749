import { makeRoute } from "../../libs/router";
import Index from './index';
import Detail from './ClassDetail';



export default [
  makeRoute('', Index),
  makeRoute('/detail', Detail),
]
