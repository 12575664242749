import React from 'react'
import {observer} from 'mobx-react'
import {Menu, Card, Table, Col, Input, Button, Tabs} from 'antd'
import store from "./store";
import { SearchForm } from 'components';
import AddForm from './AddForm';

const feedbackColumns = [
  {
    title: '学员名字',
    dataIndex: 'student_name',
  },{
    title: '回访周次',
    dataIndex: 'week',
    key: 'week',
  },
  {
    title: '内容',
    dataIndex: 'detail',
    key: 'detail',
  },
  {
    title: '回访时间',
    dataIndex: 'created_at',
    key: 'created_at',
  },{
    title: '回访人',
    dataIndex: 'teacher_name',
    key: 'teacher_name',
  },
];

const HomeworkColumns = [
  {
    title: '班级',
    dataIndex: 'class_name',
  },
  {
    title: '学员名字',
    dataIndex: 'student_name',
  }, {
    title: '作业周次',
    dataIndex: 'week',
  }, {
    title: '作业链接',
    dataIndex: 'detail',
    width: 300
  },{
    title: '提交时间',
    dataIndex: 'created_at'
  }];


@observer
class ClassStuDetail extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div >
          <Card >
            <SearchForm>
              <SearchForm.Item span={8} title="学员姓名/学号">
                <Input allowClear onChange={e => store.f_class = e.target.value} placeholder="输入学员姓名或者学号"/>
              </SearchForm.Item>
              <SearchForm.Item span={8}>
                <Button type="primary" icon="sync" onClick={store.fetchRecords}>刷新</Button>
              </SearchForm.Item>
            </SearchForm>

            <Col span={6} >
              <Menu
                onSelect={store.getSelectStudent}
                defaultSelectedKeys={['1']}
                onClick={store.showStudentDetail}
                mode="inline"
                theme="light">
                {Object.values(store.class_student_info).map(item =>(
                  <Menu.Item  key={item.id}>{item.class_name + '-' + item.name + '-' + item.qq}</Menu.Item>
                ))}
              </Menu>
            </Col>

            <Col span={18}>
              <Tabs defaultActiveKey="1"
                    onChange={store.handelStuDetail}
                    tabBarExtraContent={<Button disabled={store.tabBarStatus} onClick={store.addFeedback}>添加回访</Button>}>
                <Tabs.TabPane tab="回访信息" key="1">
                  <Table columns={feedbackColumns} dataSource={store.studentFeedback} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="作业记录" key="2">
                  <Table columns={HomeworkColumns} dataSource={store.studentHomework} />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Card>
        </div>
        {store.addVisible && <AddForm/>}
      </React.Fragment>

    )
  }
}
export default ClassStuDetail
